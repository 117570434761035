import Repository from "../Repository";
import { company, apiConfig } from "../../_helpers/apiHelper";

const resource = "/modbus";

export default {
  getOccupiedRegisters(masterId) {
    return Repository.get(
      `${resource}/occupied-registers/${masterId}`,
      apiConfig()
    );
  },

  getMasters() {
    return Repository.get(`${resource}/masters/${company()}`, apiConfig());
  },

  createMaster(payload) {
    return Repository.post(
      `${resource}/master/${company()}`,
      payload,
      apiConfig()
    );
  },

  updateMaster(masterId, payload) {
    return Repository.put(
      `${resource}/master/${masterId}`,
      payload,
      apiConfig()
    );
  },

  removeMaster(masterId) {
    return Repository.delete(`${resource}/master/${masterId}`, apiConfig());
  },

  createRegister(masterId, payload) {
    return Repository.post(
      `${resource}/register/${masterId}`,
      payload,
      apiConfig()
    );
  },

  removeRegister(registerId) {
    return Repository.delete(`${resource}/register/${registerId}`, apiConfig());
  },

  poll(registers) {
    return Repository.post(`${resource}/poll`, registers, apiConfig());
  },

  getAllRegisters(deveui) {
    return Repository.get(`${resource}/registers/${deveui}`, apiConfig())
      .then((d) => d.data)
      .catch(() => []);
  },
};
